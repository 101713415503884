import styles from './close-button.module.scss';
import { CloseIcon } from '@y2/mango/components/icons';
import { MouseEventHandler } from 'react';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const CloseButton = ({ onClick }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.closeButton}
      data-nagish="search-ui-close-button"
    >
      <CloseIcon />
    </button>
  );
};
