import styles from '../common-ui/pagination-wrapper/pagination-wrapper.module.scss';
import ItemStyles from '../common-ui/item/item.module.scss';
import { buildPaginationRange } from '../pagination-range-builder';
import texts from '../pagination.texts.json';
import { List } from '../common-ui/list/list';
import { getPageStatus } from '../common-ui/page-status';
import { Item } from '../common-ui/item/item';
import { PaginationWrapper } from '../common-ui/pagination-wrapper/pagination-wrapper';
import { ArrowButton } from '../common-ui/pagination-arrow/pagination-arrow';
import cn from 'classnames';

type Props = {
  totalPages: number;
  currentPage?: number;
  onChange?: (page: number) => void;
  disabled?: boolean;
  variant?: 'primary' | 'yad1';
};

export const ButtonPagination = ({
  totalPages,
  currentPage = 1,
  onChange,
  disabled,
  variant = 'primary',
}: Props) => {
  const pages = buildPaginationRange(totalPages, currentPage);
  const isFirstPage = currentPage <= 1;
  const isLastPage = currentPage >= totalPages;

  const getTextVariants = () =>
    `${texts.CURRENT_PAGE} ${currentPage} ${texts.TOTAL_PAGES} ${totalPages}`;

  return (
    <PaginationWrapper>
      <ArrowButton
        type="prev"
        currentPage={currentPage}
        onChange={onChange}
        isDisabled={disabled || isFirstPage}
      />
      <List>
        {pages.map((page) => {
          const pageInfo = getPageStatus(page, currentPage, pages, totalPages);
          return (
            <Item key={page} pageInfo={pageInfo}>
              <button
                onClick={() => onChange?.(page)}
                aria-current={pageInfo.isSelected ? 'page' : false}
                className={cn(ItemStyles.item, ItemStyles[variant])}
                disabled={disabled}
              >
                {page}
              </button>
            </Item>
          );
        })}
      </List>
      <span className={styles.textVariant}>{getTextVariants()}</span>
      <ArrowButton
        type="next"
        currentPage={currentPage}
        onChange={onChange}
        isDisabled={disabled || isLastPage}
      />
    </PaginationWrapper>
  );
};
