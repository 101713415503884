import range from 'lodash/range';
import {
  FIRST_PAGE_INDEX,
  MIN_DOTS_LENGTH,
  DOTS_COUNT,
  FIRST_PAGE_COUNT,
  LAST_PAGE_COUNT,
  PAGES_CAPACITY_IN_BAR,
  SIBLING_COUNT,
} from './consts';

export type PageList = number[];

export const buildPaginationRange = (
  totalPages: number,
  currentPage: number,
): PageList => {
  // No need for dots
  if (totalPages <= PAGES_CAPACITY_IN_BAR) {
    return range(FIRST_PAGE_INDEX, totalPages + 1);
  }

  const leftSiblingIndex = Math.max(currentPage - SIBLING_COUNT, 1);
  const rightSiblingIndex = Math.min(currentPage + SIBLING_COUNT, totalPages);

  const hasLeftDots = leftSiblingIndex > MIN_DOTS_LENGTH;
  const hasRightDots = rightSiblingIndex < totalPages - MIN_DOTS_LENGTH;

  const lastPageIndex = totalPages;

  if (!hasLeftDots && hasRightDots) {
    const leftItemCount = PAGES_CAPACITY_IN_BAR - DOTS_COUNT - LAST_PAGE_COUNT;
    const leftRange = range(FIRST_PAGE_INDEX, leftItemCount + 1);
    return [...leftRange, totalPages];
  }

  if (hasLeftDots && !hasRightDots) {
    const rightItemCount =
      PAGES_CAPACITY_IN_BAR - DOTS_COUNT - FIRST_PAGE_COUNT;
    const rightRange = range(totalPages - rightItemCount + 1, totalPages + 1);
    return [FIRST_PAGE_INDEX, ...rightRange];
  }

  const middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);
  return [FIRST_PAGE_INDEX, ...middleRange, lastPageIndex];
};
