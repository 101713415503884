import styles from '../common-ui/pagination-wrapper/pagination-wrapper.module.scss';
import { PaginationWrapper } from '../common-ui/pagination-wrapper/pagination-wrapper';
import { ArrowLink } from '../common-ui/pagination-arrow/pagination-arrow';
import Link from 'next/link';
import cn from 'classnames';
import { buildPaginationRange } from '../pagination-range-builder';
import { NextRouter } from 'next/router';
import texts from '../pagination.texts.json';
import { UrlObject } from 'url';
import { List } from '../common-ui/list/list';
import { getPageStatus } from '../common-ui/page-status';
import { Item } from '../common-ui/item/item';
import { getPageLink } from '../helper';
import ItemStyles from '../common-ui/item/item.module.scss';

export type PaginationProps = {
  totalPages: number;
  currentPage?: number;
  path: NextRouter['pathname'];
  onChange?: (page: number) => string | UrlObject;
  query: NextRouter['query'];
  hash?: string;
  variant?: 'primary' | 'yad1';
};

const disabledAttributes = {
  'aria-disabled': true,
  tabIndex: -1,
};

export const Pagination = ({
  totalPages,
  currentPage = 1,
  path,
  onChange,
  query,
  hash,
  variant = 'primary',
}: PaginationProps) => {
  const pages = buildPaginationRange(totalPages, currentPage);
  const isFirstPage = currentPage <= 1;
  const isLastPage = currentPage >= totalPages;

  const getTextVariants = () =>
    `${texts.CURRENT_PAGE} ${currentPage} ${texts.TOTAL_PAGES} ${totalPages}`;

  return (
    <PaginationWrapper>
      <ArrowLink
        type="prev"
        currentPage={currentPage}
        path={path}
        onChange={onChange}
        isDisabled={isFirstPage}
        query={query}
      />
      <List>
        {pages.map((page) => {
          const pageInfo = getPageStatus(page, currentPage, pages, totalPages);
          const href = getPageLink(path, query, page.toString(), hash);
          return (
            <Item key={`page-${page}`} pageInfo={pageInfo}>
              <Link
                href={href}
                as={onChange?.(page)}
                aria-label={`${texts.PAGE} ${page}`}
                data-nagish="pagination-item-link"
                aria-current={pageInfo.isSelected ? 'page' : false}
                {...(pageInfo.isSelected ? disabledAttributes : {})}
                className={cn(ItemStyles.item, ItemStyles[variant])}
              >
                {page}
              </Link>
            </Item>
          );
        })}
      </List>
      <span className={styles.textVariant}>{getTextVariants()}</span>
      <ArrowLink
        type="next"
        currentPage={currentPage}
        path={path}
        onChange={onChange}
        isDisabled={isLastPage}
        query={query}
      />
    </PaginationWrapper>
  );
};
