import { PageList } from '../pagination-range-builder';

export type PageInfo = {
  isSelected: boolean;
  isFirstPage: boolean;
  showFrontDots: boolean;
  showBackDots: boolean;
};

export const getPageStatus = (
  page: number,
  currentPage: number,
  list: PageList,
  totalPages: number,
): PageInfo => {
  const isSelected = page === currentPage;
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;
  const showFrontDots = isFirstPage && list[1] - list[0] > 1;
  const showBackDots =
    // eslint-disable-next-line no-magic-numbers
    isLastPage && list[list.length - 1] - list[list.length - 2] > 1;

  return {
    isSelected,
    isFirstPage,
    showFrontDots,
    showBackDots,
  };
};
