import styles from './list.module.scss';
import { HtmlHTMLAttributes } from 'react';

type OLAttributes = HtmlHTMLAttributes<HTMLOListElement>;

type ListProps = {
  children: React.ReactNode;
} & OLAttributes;

export const List = ({ children, ...props }: ListProps) => (
  <ol {...props} className={styles.list}>
    {children}
  </ol>
);
