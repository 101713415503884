import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';
import styles from './pagination-arrow.module.scss';
import Link from 'next/link';

import { NextRouter } from 'next/router';
import { getPageLink } from '../../helper';
import texts from './pagination-arrow.texts.json';
import { UrlObject } from 'url';

type LinkProps = {
  type: 'next' | 'prev';
  isDisabled: boolean;
  currentPage: number;
  path: NextRouter['pathname'];
  onChange?: (page: number) => string | UrlObject;
  query: NextRouter['query'];
};

export const ArrowLink = ({
  type,
  isDisabled,
  currentPage,
  path,
  onChange,
  query,
}: LinkProps) => {
  const buttonIsTypeNext = type === 'next';
  const page = buttonIsTypeNext ? currentPage + 1 : currentPage - 1;
  const href = getPageLink(path, query, page.toString());
  const ArrowIcon = buttonIsTypeNext ? ChevronLeftIcon : ChevronRightIcon;
  return (
    <Link
      href={href}
      as={onChange?.(page)}
      className={styles.button}
      aria-label={buttonIsTypeNext ? texts.NEXT_PAGE : texts.PREV_PAGE}
      aria-disabled={isDisabled}
      data-nagish="pagination-item-link"
    >
      <ArrowIcon className={styles['icon']} />
    </Link>
  );
};

type ButtonProps = {
  type: 'next' | 'prev';
  isDisabled?: boolean;
  currentPage: number;
  onChange?: (page: number) => void;
};

export const ArrowButton = ({
  type,
  isDisabled,
  currentPage,
  onChange,
}: ButtonProps) => {
  const buttonIsTypeNext = type === 'next';
  const page = buttonIsTypeNext ? currentPage + 1 : currentPage - 1;
  const ArrowIcon = buttonIsTypeNext ? ChevronLeftIcon : ChevronRightIcon;
  return (
    <button
      onClick={() => onChange?.(page)}
      className={styles.button}
      aria-label={buttonIsTypeNext ? texts.NEXT_PAGE : texts.PREV_PAGE}
      data-nagish="pagination-item-link"
      disabled={isDisabled}
    >
      <ArrowIcon className={styles.icon} />
    </button>
  );
};
