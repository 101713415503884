import React from 'react';
import { BaseCarousel } from '../../../../components/carousel/base-carousel/base-carousel';
import styles from './similar-projects-carousel.module.scss';
type Props = {
  children?: React.ReactNode;
};

export const SimilarProjectsCarousel = ({ children }: Props) => {
  return (
    <BaseCarousel
      navigationProps={{
        className: styles.carouselNavigation,
      }}
      swiperProps={{
        slidesPerView: 'auto',
        spaceBetween: 22,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        breakpoints: {
          880: {
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
        },
      }}
    >
      {children}
    </BaseCarousel>
  );
};
