import styles from './container.module.scss';
import cn from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const Container = ({ className, children }: Props) => {
  return <div className={cn(styles.containerBox, className)}>{children}</div>;
};
