import styles from './highlighted-text.module.scss';
import React from 'react';

type Props = {
  text: string;
  searchTerm?: string;
  'data-testid'?: string;
};

export const HighlightedText = ({
  text,
  searchTerm,
  'data-testid': dataTestId,
}: Props) => {
  if (!searchTerm) {
    return <span>{text}</span>;
  }

  const textsParts = text.split(searchTerm);

  return (
    <span data-testid={dataTestId} className={styles.text}>
      {textsParts.map((part, index) => (
        <React.Fragment key={`${part}-${index}`}>
          {index !== 0 && <b>{searchTerm}</b>}
          {part}
        </React.Fragment>
      ))}
    </span>
  );
};
