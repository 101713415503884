import styles from './pagination-wrapper.module.scss';
import cn from 'classnames';
import texts from '../../pagination.texts.json';
export type PaginationProps = {
  className?: string;
  children?: React.ReactNode;
};
export const PaginationWrapper = ({ className, children }: PaginationProps) => {
  return (
    <nav
      className={cn(styles.pagination, className)}
      data-nagish="pagination-navbar"
      aria-label={texts.LABEL}
    >
      {children}
    </nav>
  );
};
