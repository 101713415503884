import { NextRouter } from 'next/router';
import { UrlObject } from 'url';

type GetPageLink = (
  path: string,
  query: NextRouter['query'],
  page: string,
  hash?: string,
) => UrlObject;
export const getPageLink: GetPageLink = (
  path: string,
  query: NextRouter['query'],
  page: string,
  hash?: string,
) => ({
  pathname: path,
  query: { ...query, page },
  hash,
});
