import { categories } from '@y2/categories';
import texts from './categories.text.json';

const {
  realEstate: { subCategories },
} = categories;

export const SUBCATEGORIES_ENG = {
  FOR_SALE: subCategories.forSale.title,
  RENT: subCategories.rent.title,
  COMMERCIAL: subCategories.commercial.title,
} as const;

export const SUBCATEGORIES_HEB = {
  FOR_SALE: subCategories.forSale.titleHeb,
  RENT: subCategories.rent.titleHeb,
  COMMERCIAL: subCategories.commercial.titleHeb,
} as const;

export const SUBCATEGORIES_ID = {
  FOR_SALE: `${subCategories.forSale.id}`,
  RENT: `${subCategories.rent.id}`,
  COMMERCIAL: `${subCategories.commercial.id}`,
} as const;

export type SubcategoriesIds =
  | typeof SUBCATEGORIES_ID.FOR_SALE
  | typeof SUBCATEGORIES_ID.RENT
  | typeof SUBCATEGORIES_ID.COMMERCIAL;

export const ALL_SUB_CATEGORY_HEB = texts.all;

export const getSubcategoryHebById = (subcategoryId: SubcategoriesIds) =>
  ({
    [SUBCATEGORIES_ID.FOR_SALE]: SUBCATEGORIES_HEB.FOR_SALE,
    [SUBCATEGORIES_ID.RENT]: SUBCATEGORIES_HEB.RENT,
    [SUBCATEGORIES_ID.COMMERCIAL]: SUBCATEGORIES_HEB.COMMERCIAL,
  })[subcategoryId] || ALL_SUB_CATEGORY_HEB;
