import React from 'react';
import texts from './texts.json';
import { SwiperSlide } from 'swiper/react';
import { Project } from '@y2/api-clients/yad1';
import styles from './similar-projects.module.scss';
import { SimilarProjectItem } from '../../project-item/similar-project-item/similar-project-item';
import { SimilarProjectsCarousel } from './similar-projects-carousel/similar-projects-carousel';

type Props = {
  similarProjects: Project[];
};

export function SimilarProjects({ similarProjects }: Props) {
  return (
    <div className={styles.similarProjectsBox}>
      <h2 className={styles.heading}>{texts.heading}</h2>
      <SimilarProjectsCarousel>
        {similarProjects.map((project) => (
          <SwiperSlide className={styles.slide} key={project.id}>
            <SimilarProjectItem project={project} />
          </SwiperSlide>
        ))}
      </SimilarProjectsCarousel>
    </div>
  );
}
