import { HtmlHTMLAttributes } from 'react';
import styles from './item.module.scss';
import cn from 'classnames';
import { PageInfo } from '../page-status';

type LiAttributes = HtmlHTMLAttributes<HTMLLIElement>;

type ItemProps = {
  children: React.ReactNode;
  pageInfo: PageInfo;
} & LiAttributes;

export const Item = ({ children, pageInfo, ...props }: ItemProps) => {
  return (
    <li
      {...props}
      className={cn(
        styles.itemContainer,
        pageInfo.showBackDots && styles.backDots,
        pageInfo.showFrontDots && styles.frontDots,
      )}
    >
      {children}
    </li>
  );
};
