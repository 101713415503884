import styles from './mobile-drawer-header.module.scss';
import { MouseEventHandler } from 'react';
import { CloseButton } from '../close-button';
import cn from 'classnames';

type Props = {
  children: React.ReactNode;
  onCloseClick: MouseEventHandler<HTMLButtonElement>;
};

export const MobileDrawerHeader = ({ children, onCloseClick }: Props) => {
  return (
    <div className={cn(styles.mobileDrawerHeader)}>
      <span className={styles.title}>{children}</span>
      <CloseButton onClick={onCloseClick} />
    </div>
  );
};
