import React from 'react';
import styles from './no-search-results.module.scss';
import texts from './texts.json';
import { NoResultsSpotIllustration } from '@y2/mango/components/illustrations/spot';
import { SimilarProjects } from './similar-projects/similar-projects';
import { Project } from '@y2/api-clients/yad1';

type Props = {
  similarProjects?: Project[];
};

export function NoSearchResults({ similarProjects = [] }: Props) {
  return (
    <div className={styles.noSearchResultsBox}>
      <div className={styles.noSearchResultsContainer}>
        <NoResultsSpotIllustration className={styles.illustration} />
        <span className={styles.heading}>{texts.heading}</span>
      </div>
      {similarProjects.length > 0 && (
        <SimilarProjects similarProjects={similarProjects} />
      )}
    </div>
  );
}
